.modal-outer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    z-index: 1021;
    background: rgba(0, 0, 0, 0.8);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: 300ms;
    overflow-y: scroll;
    opacity: 0;
}

.modal-outer.active {
    opacity: 1;
}

.modal-inner {
    min-width: 720px;
    background-color: white;
    max-width: 720px;
    margin: 25px auto;
    display: block;
    border-radius: 5px;
    position: relative;
    transition: 500ms;
    transform: translateY(100%);
}

.modal-outer.active .modal-inner {
    transform: translateY(0);
}

.modal-title {
    font-family: Circular;
    font-size: 20px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    border-bottom: 1px solid lightgrey;
}

.modal-close-button.tock-icon {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 24px;
    cursor: pointer;
    padding: 10px 5px;
    width: 44px;
    height: 44px;
    border-radius: 22px;
    display: flex;
    justify-content: center;
    align-content: center;
    background: transparent;
    margin-top: 10px;
    margin-right: 10px;
}

.modal-close-button.tock-icon:hover {
    background: #f6f6f6;
}

.modal-back-button {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 24px;
    cursor: pointer;
    padding: 16px 5px;
    width: 44px;
    height: 44px;
    border-radius: 22px;
    display: flex;
    justify-content: center;
    align-content: center;
    background: transparent;
    margin-top: 10px;
    margin-left: 11px;
    transform: rotate(90deg);
}

.modal-back-button:hover {
    background: #f6f6f6;
}

.modal-back-button img {
    height: 13px;
    width: auto;
}

.modal-inner {
    max-height: 95vh;
    overflow-y: hidden;
}

.modal-content-area {
    height: calc(100% - 60px);
    overflow-y: auto;
}

@media (max-width: 991px) {
    .modal-inner {
        max-width: 100%;
        min-width: unset;
        max-height: 100vh;
        width: 100%;
    }

    .modal-title {
        font-size: 15px;
    }

    .modal-content-area {
        background: white;
    }

    .listado-promos .promo-image {
        margin-right: 0.5em;
    }

    .modal-close-button.tock-icon {
        margin-top: 6px;
    }

    .modal-back-button {
        margin-top: 8px;
        margin-left: 5px;
    }
}
