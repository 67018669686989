.mobile-navbar {
    position: relative;
    height: 75px;
    background-color: transparent;
}

.mobile-navbar .menu-button {
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: transparent;
}

.mobile-navbar .menu-button:focus {
    outline: none;
}

body.scrolled .mobile-navbar {
    background-color: #fbf6f3;
    background-image: none;
}

.mobile-navbar .reservar-ahora,
.mobile-navbar .logo {
    width: 180px;
    align-items: center;
    text-align: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
    border: none;
}

.mobile-navbar .reservar-ahora {
    display: none;
    font-family: Circular;
    height: 37px;
    color: white;
    text-transform: uppercase;
    background-color: rgb(41, 41, 41);
    border-radius: 20px;
    font-weight: 400;
    font-size: 12px;
}

/* body.scrolled .mobile-navbar .reservar-ahora {
	display: flex;
} */

.mobile-menu-overlay {
    display: none;
}

.mobile-menu-overlay {
    position: fixed;
    z-index: 1021;
    top: -50vh;
    left: 0;
    right: 0;
    bottom: -50vh;
    background-color: rgba(0, 0, 0, 0.5);
}

.mobile-menu {
    left: -300px;
    position: fixed;
    transition: 300ms;
    position: fixed;
    top: 0;
    z-index: 1022;
    width: 300px;
    height: 100vh;
    flex-direction: column;
    overflow-y: auto;
    background: #fbf6f2;
}

.mobile-menu.active,
.mobile-menu-overlay.active {
    display: block;
    left: 0;
}

.mobile-menu a:not(.btn) {
    display: block;
    font-family: Circular;
    color: var(--gris-texto);
    padding: 1rem;
    border-top: 1px solid #9797972b;
    font-size: 1.25rem;
    text-transform: capitalize;
    font-weight: normal;
    padding-left: 0px;
    margin: 0;
    margin-left: 5%;
}

.mobile-menu a:not(.btn):first-child {
    border-top: none;
}

.mobile-menu .btn {
    display: block;
    position: absolute;
    margin-top: 2rem;
    left: 0.8rem;
    width: 90%;
}

.mobile-menu .user-account-button {
    margin-left: 0;
}

.mobile-menu a.user-account + a {
    border-top: none;
}

.mobile-menu hr {
    margin-left: 1em;
    margin-right: 0;
    border-color: #ebe6e4;
    margin-top: 5px;
}

.mobile-menu .user-actions {
    padding-top: 25px;
    flex: 1;
    margin-right: 80px;
    margin-left: 36px;
}

.mobile-menu .user-menu {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 1em;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.mobile-menu .user-actions a {
    border: none;
    font-family: Circular, sans-serif;
    font-size: 13px;
    padding: 0 0 12px;
    position: relative;
    margin-bottom: 13px;
    text-transform: uppercase;
    border-bottom: 1px solid #ebe6e4;
    margin-right: 1em;
    text-align: left;
}

.mobile-menu .user-actions a .icon-svg-container {
    position: absolute;
    left: -43px;
    top: 25%;
    transform: translateY(-40%);
}

.mobile-menu .user-actions a .icon-svg-container,
.mobile-menu .user-actions a .icon-svg-container > img {
    width: 32px !important;
    height: 32px !important;
}

.mobile-menu .user-actions a:last-child {
    border-bottom: none;
}

@media (max-width: 991px) {
    body.modal-open header,
    body.modal-open header.simple,
    body.scrolled.modal-open header,
    body.scrolled.modal-open header.simple {
        height: 0;
    }

    body.scrolled.modal-open .mobile-navbar {
        display: none;
    }

    header.simple .mobile-navbar .menu-button {
        display: none;
    }

    .mobile-navbar .reservar-ahora,
    .mobile-navbar .logo {
        width: 165px;
    }

    .mobile:not(.simple) .mobile-navbar .reservar-ahora,
    .mobile:not(.simple) .mobile-navbar .logo {
		margin-left: 13px;
	}

    header.mobile .btn.btn-brown {
        height: auto;
    }
}
