.suscribete .section {
    display: flex;
    justify-content: space-evenly;
}

.suscribete .section > div {
    flex: 1;
    min-height: 90vh;
    margin-bottom: 100px;
}

.suscribete .section.single-block > div {
    min-height: 300px;
}

.suscribete .section .block.image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 66vh;
    margin: 12vh;
    height: 66vh;
    min-height: unset;
    min-width: unset;
    max-width: 66vh;
    border-radius: 30px;
}

.suscribete .section .block.text {
    padding: 0 6vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.suscribete .section:not(.single-block) .block.text:first-child {
    margin-left: auto;
    margin-right: 0;
    padding-right: 2vw;
    flex: 0;
    min-width: 40vw;
}

.suscribete .section:not(.single-block):last-child .block.text:first-child {
    padding-bottom: 0;
    margin-bottom: 0;
}

.suscribete .section:not(.single-block) .block.text:last-child {
    margin-left: 6vw;
    margin-right: auto;
    padding-left: 0;
    flex: 0;
    min-width: 40vw;
}

.suscribete .section .block.text .supertitle {
    color: var(--gris-texto);
    font-family: Circular;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 2px;
    line-height: 1.5;
    text-transform: uppercase;
    margin: 0;
    max-width: unset;
    padding-bottom: 0;
}

.suscribete .section .block.text > *:first-child {
    margin-top: auto;
}

.suscribete .section .block.text > *:last-child {
    margin-bottom: auto;
}

.suscribete .section .block.text h2 {
    color: var(--gris-texto);
    font-family: Circular;
    font-size: 60px;
    letter-spacing: 1px;
    line-height: 1.1;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.suscribete .section .block.text > div {
    color: #5c5c5c;
    font-family: Circular;
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 32px;
    margin: 1.5rem 0;
    max-width: 90%;
    padding-bottom: 1rem;
}

.suscribete .section.single-block .block.text > div {
    color: #5c5c5c;
    font-family: Circular;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    margin: 1.5rem auto;
    padding: 0 3rem;
    max-width: 900px;
}

.suscribete .section .block.text h2 + div {
    margin-top: 0;
}

.suscribete .section .block.text .btn {
    margin-left: 0;
    margin-right: auto;
    padding-left: 55px;
    padding-right: 55px;
    margin-top: 25px;
}

.suscribete .section.single-block .block.text .btn {
    margin-left: auto;
}

.suscribete .section .block.text .icons {
    display: flex;
    justify-content: flex-start;
}

.suscribete .section .block.text .icons > div {
    width: 128px;
    height: 128px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.suscribete .section.section.single-block .block.text .icons {
    display: grid;
    grid-template-columns: repeat(4, 4fr);
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
}

.suscribete .section.single-block .block.text .icons > div {
    width: auto;
    min-width: 10.4vw;
    height: 10.4vw;
}

.suscribete .section.single-block .block.text h2 {
    text-align: center;
    font-family: Circular;
    font-size: 44px;
    letter-spacing: 1px;
    line-height: 48px;
    margin-bottom: 0.5em;
    margin-top: 0;
}

.suscribete .section .block.offer {
    padding: 0 6vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.suscribete .section.single-block .block.offer h2 {
    text-align: center;
    font-family: Circular;
    font-size: 44px;
    letter-spacing: 1px;
    line-height: 48px;
    margin-bottom: 0.5em;
    margin-top: 0;
}

.suscribete .section .negocios-inner {
    display: grid;
    grid-template-columns: repeat(3, 3fr);
    gap: 20px;
    margin-bottom: 25px;
}

.suscribete .section .block.offer .icons {
    align-items: center;
    bottom: 15px;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 15px;
}

.suscribete .section .block.offer .negocio {
    position: relative;
    animation-delay: .5s;
    animation-duration: .5s;
    animation-fill-mode: forwards;
    animation-name: carousel-item-container;
    border: solid transparent;
    border-radius: 30px;
    border-width: 1px 1px 5px;
    box-shadow: 0 0 15px rgba(0,0,0,.1);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    opacity: 0;
    padding: 10px;
    transition: .5s;
}

.suscribete .section .block.offer .negocio-name {
    color: #333;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
}

.suscribete .section .block.offer .negocio .image-container {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;
    height: 60%;
    position: relative;
}

.suscribete .faq {
    max-width: 1100px;
    margin: 0 auto;
    opacity: 0;
}

.suscribete .faq h2 {
    text-align: center;
    font-family: Circular;
    font-size: 44px;
    letter-spacing: 1px;
    line-height: 48px;
    margin-top: 0;
    margin-bottom: 50px;
}

.suscribete .faq.loaded {
    opacity: 1;
}

.suscribete .faq .question {
    position: relative;
    background-color: #f8f8f8;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.suscribete .faq .question img {
    height: 10px;
    transition: .3s;
    width: 17px;
}

.suscribete .faq .faq-container.active .question img {
    transform: rotate(180deg);
}

.suscribete .faq .answer {
    padding: 15px 15px 25px 15px;
    transition: 300ms;
}

.suscribete .faq .faq-container {
    max-width: 1100px;
    margin: 0 auto 10px auto;
}

.suscribete .section.single-block .faq {
    margin-top: 100px;
    border-top: 1px solid lightgrey;
    margin: 0;
    padding: 50px 0;
    border-bottom: 1px solid lightgrey;
    margin: 50px 0;
    width: 100%;
    min-width: 100%;
}

@media(max-width: 1300px) {
    .suscribete .subscription-details {
        padding-left: 50px;
        padding-right: 50px;
    }
}