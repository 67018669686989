.venue-extras.upselling .extras .icon {
    position: relative;
    display: inline-block;
    margin: 0 10px 5px 0;
}

.venue-extras.upselling .extras .image + .icon {
    margin-left: 5px;
}

.venue-extras.upselling .extras .icon img {
    height: 28px;
    width: 28px;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
}

.venue-extras.upselling .extras .city {
    color: grey;
    font-size: 13px;
    font-weight: 400;
    line-height: 1;
    text-transform: uppercase;
    margin-bottom: 3em;
    margin-left: 5px;
    margin-top: 3px;
}

.venue-extras.upselling .sidebar .inner {
    transition: 200ms;
}

.venue-extras.upselling .sidebar .sidebar-quantity {
    align-items: center;
    background: var(--resaltado);
    color: #fff;
    display: flex;
    font-size: 12px;
    justify-content: center;
    margin-right: .5em;
    text-align: center;
    width: 20px;
    height: 20px;
    border-radius: 10px;
}

.venue-extras.upselling .sidebar .preorder-selection-information {
    margin: 1em -15px 0;
    padding-top: 1em;
    border-top: 1px solid lightgrey;
}

.venue-extras.upselling .sidebar .preorder-selection-information .item {
	position: relative;
    padding: 0 15px;
    font-size: 13px;
    margin-bottom: 10px;
}

.venue-extras.upselling .sidebar .preorder-selection-information .item .title {
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 5px;
    text-transform: uppercase;
}

.venue-extras.upselling .sidebar .preorder-selection-information .item .details {
    display: grid;
    grid-template-columns: 2fr 2fr;
}

.venue-extras.upselling .sidebar .preorder-selection-information .item .details > div {
    display: flex;
    align-items: center;
}

.venue-extras.upselling .sidebar .preorder-selection-information .item .details > div > span:first-child {
    margin-right: 5px;
}

.venue-extras.upselling .sidebar .preorder-selection-information .item .extra-row {
    display: flex;
    align-items: center;
    font-size: 0.9em;
    margin-bottom: 2px;
}

.venue-extras.upselling .sidebar .preorder-selection-information .item .extra-row:first-child {
    font-size: 0.9em;
    margin-top: 5px;
}

.venue-extras.upselling .sidebar .preorder-selection-information .item .extra-row:last-child {
    margin-bottom: 15px;
}

.venue-extras.upselling .sidebar .preorder-selection-information .item .extra-row > span:first-child {
    align-items: center;
    color: var(--gris-texto);
    border: 1px solid var(--gris-texto);
    background-color: #fff;
    display: flex;
    font-size: 12px;
    justify-content: center;
    margin-right: .5em;
    text-align: center;
    width: 20px;
    height: 20px;
    border-radius: 10px;
}

.venue-extras.upselling .sidebar .preorder-selection-information .item .extra-row > span:last-child {
    margin-left: auto;
}

.venue-extras.upselling .sidebar .preorder-selection-information .item .delete-button {
    align-items: center;
    background-color: var(--resaltado-transparente);
    border-radius: 9.5px;
    cursor: pointer;
    display: flex;
    height: 19px;
    justify-content: center;
    position: absolute;
    right: 15px;
    top: 0;
    width: 19px;
}

.venue-extras.upselling .sidebar .preorder-selection-information .item .delete-button:hover {
    background-color: #f0f0f0;
}

.venue-extras.upselling .extras .category-extras .extra h3 {
    margin: 10px 0 0 5px;
    font-size: 17px;
}

.venue-extras.upselling .extras .category-extras .extra .lower {
    position: absolute;
    bottom: 10px;
    display: flex;
    flex-direction: column;
}

.venue-extras.upselling .extras .category-extras .extra .lower-tags {
    display: flex;
}

.venue-extras.upselling .extras .category-extras .extra .iconos-detalle {
    margin-bottom: 8px;
}

.venue-extras.upselling .extras .category-extras .extra .iconos-detalle .icon {
    margin: 0 0 0 5px;
}

.venue-extras.upselling .extras .category-extras .extra .iconos-detalle .icon > img {
    width: 26px;
    height: 26px;
    filter: grayscale(1);
}

.venue-extras.upselling .extras .category-extras .extra:hover .iconos-detalle .icon > img {
    filter: grayscale(0);
}

.venue-extras.upselling .extras .category-extras .extra .image {
    width: 135px;
    position: relative;
}

.venue-extras.upselling .extras .category-extras .extra .image .iconos {
    position: absolute;
    bottom: 5px;
    right: 5px;
    display: flex;
    justify-content: flex-end;
}

.venue-extras.upselling .extras .category-extras .extra .image .iconos .icon {
    margin-right: 5px;
}

.venue-extras.upselling .extras .category-extras .extra .image .iconos .icon > img {
    width: 26px;
    height: 26px;
}

.venue-extras.upselling .extras .category-extras .extra .price-tag {
    position: unset;
    height: 25px;
    margin: 0 5px 0 0;
}

.venue-extras.upselling .extras .category-extras .extra .average-rating {
    display: flex;
    align-items: center;
    border: 2px solid #dedede;
    border-radius: 20px;
    font-size: 12px;
    padding: 3px 13px 3px 8px;
    height: 25px;
}

.venue-extras.upselling .extras .category-extras .extra .image {
    height: 135px;
}

.venue-extras.upselling .extras .category-extras .extra .average-rating > span {
    transform: scale(0.75);
}

.venue-extras.upselling .extras-modal .main-page .slider .thumbnails {
    display: flex;
}

.venue-extras.upselling .extras-modal .main-page .inner {
    margin: 0 25px;
}

.venue-extras.upselling .extras-modal .main-page .title {
    font-size: 28px;
    margin-bottom: 0;
    text-transform: uppercase;
    margin-top: 0.5em;
    font-weight: 700;
}

.venue-extras.upselling .extras-modal .main-page .address {
    color: #5c5c5c;
    font-family: Circular, sans-serif;
    font-size: 1.1em;
    font-weight: 400;
    margin: 0 0 15px;
}

.venue-extras.upselling .extras-modal .main-page .event-info {
    border-top: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
    padding-top: 1em;
    display: grid;
    gap: 15px;
    grid-template-columns: 2fr 2fr;
    margin-bottom: 1em;
    padding-bottom: 0.5em;
    font-size: 15px;
}

.venue-extras.upselling .extras-modal .main-page .widget-horario .composite-component:not(:last-of-type) {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
}

.venue-extras.upselling .extras-modal .main-page .icons .icon-container {
    position: relative;
    display: inline-block;
    margin-right: 0.5em;
    width: 48px;
    height: 48px;
}

.venue-extras.upselling .extras-modal .main-page .description {
    padding: 1em 0;
    margin-bottom: 1em;
    padding-top: 1em;
    margin-top: 1em;
    border-top: 1px solid lightgrey;
}

.venue-extras.upselling .extras-modal .main-page .description p:first-child {
    margin-top: 0;
}

.venue-extras.upselling .extras-modal .main-page .description p:last-child {
    margin-bottom: 0;
}

.venue-extras.upselling .extras-modal .main-page .facts > div {
    align-items: flex-start;
    color: #5c5c5c;
    display: flex;
    font-family: Circular;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px;
}

.venue-extras.upselling .extras-modal .main-page .facts img {
    background-size: contain;
    height: 50px;
    margin-right: 1.5em;
    width: 50px;
}

.venue-extras.upselling .extras-modal .main-page .facts > div > div {
    display: flex;
    flex-direction: column;
    font-size: 17px;
}

.venue-extras.upselling .extras-modal .main-page .carousel-container {
    height: 420px;
}

.venue-extras.upselling .extras-modal .main-page .events .image-container {
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    box-shadow: 0 4px 8px 0 rgb(27 28 36/8%);
    cursor: pointer;
    margin-bottom: 25px;
}

.venue-extras.upselling .extras-modal .main-page .events .item-image {
    background-position: 50%;
    background-size: cover;
    border-radius: 20px 20px 0 0;
    min-height: 148px;
    min-width: 150px;
    width: 100%;
    background-repeat: no-repeat;
    height: 90px;
    margin: 0 1em 0 0;
}

.venue-extras.upselling .extras-modal .main-page .events .item-image img {
    opacity: 0;
}

.venue-extras.upselling .extras-modal .main-page .events h3 {
    color: #333;
    font-size: 15px;
    font-weight: 600;
    line-height: 1;
    margin: 15px 15px 0;
    padding: 0;
    text-transform: uppercase;
}

.venue-extras.upselling .extras-modal .main-page .events .details-tag {
    align-items: center;
    display: inline-flex;
    font-size: 11px;
    justify-content: space-evenly;
    margin-right: 5px;
    margin-top: 5px;
    text-transform: uppercase;
    margin: 10px 15px 15px 15px;
    background: white;
    color: var(--gris-texto);
    font-weight: 200;
    border: 2px solid #dedede;
    border-radius: 20px;
    padding: 3px 13px;
}

.venue-extras.upselling .extras-modal .main-page .description-text {
    background: var(--resaltado-claro);
    border-radius: 5px;
    color: var(--gris-texto);
    font-size: .9rem;
    margin-bottom: auto;
    padding: 10px 27px;
    border-radius: 20px;
    max-height: 150px;
}

.venue-extras.upselling .extras-modal .main-page .description-text h3 {
    margin: 0.5em 0 0.75em;
    margin-bottom: 20px;
    max-width: calc(100% - 65px);
}

.venue-extras.upselling .extras-modal .main-page .encuestas-clientes {
    padding-top: 1em;
    margin-top: 1em;
    border-top: 1px solid lightgrey;
    padding-bottom: 50px;
}

.venue-extras.upselling .extras-modal .main-page .encuestas-clientes .columna {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(3,3fr);
}

.venue-extras.upselling .extras-modal .main-page .encuestas-clientes .category {
    background: #f6f6f6;
    padding: 15px 20px;
}

.venue-extras.upselling .extras-modal .main-page .encuestas-clientes .category h2 {
    margin-bottom: .5em;
    font-size: 18px;
    line-height: 24px;
}

.venue-extras.upselling .extras-modal .main-page .encuestas-clientes .category .star-meter > span {
    font-size: 26px;
}

.venue-extras.upselling .extras-modal .calendar-page .top-bar {
    align-items: center;
    display: flex;
    margin: 25px 25px 0;
}

.venue-extras.upselling .extras-modal .calendar-page .pax-selector {
    align-items: center;
    border: 1px solid #d3d3d3;
    box-sizing: border-box;
    display: flex;
    flex: 1;
    height: 75px;
    justify-content: center;
    border-radius: 3px 0 0 3px;
    padding: 0 25px;
    user-select: none;
}

.venue-extras.upselling .extras-modal .calendar-page .pax-selector .pax {
    flex: 1;
}

.venue-extras.upselling .extras-modal .calendar-page .pax-selector .pax-button {
    align-items: center;
    background: #fff;
    border-radius: 30px;
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: center;
    width: 40px;
}

.venue-extras.upselling .extras-modal .calendar-page .date-display {
    border-left: none;
    border-radius: 0 3px 3px 0;
    align-items: center;
    border: 1px solid #d3d3d3;
    box-sizing: border-box;
    display: flex;
    flex: 1;
    height: 75px;
    justify-content: center;
}

.venue-extras.upselling .extras-modal .calendar-page .slots {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
}

.venue-extras.upselling .extras-modal .calendar-page .slot-row {
    align-items: center;
    border: 1px solid #d3d3d3;
    border-radius: 3px;
    color: var(--gris-texto);
    cursor: pointer;
    display: flex;
    font-family: Circular,sans-serif;
    font-size: 16px;
    margin: 5px 25px;
    padding: 15px;
    transition: .3s;
    user-select: none;
}

.venue-extras.upselling .extras-modal .calendar-page .slot-time {
    margin-right: 1em;
}

.venue-extras.upselling .extras-modal .calendar-page .available-pax {
    background: var(--resaltado);
    border-radius: 5px;
    color: #fff;
    font-size: 13px;
    font-weight: 700;
    padding: 2px 10px;
    text-transform: uppercase;
}

.venue-extras.upselling .extras-modal .calendar-page .slot-amount {
    margin-left: auto;
}

.venue-extras.upselling .extras-modal .extras-page {
    padding: 0 25px 135px 25px;
}

.venue-extras.upselling .extras-modal .extras-page .extra-category .title {
    font-size: 20px;
    text-transform: none;
    font-weight: 700;
    margin-top: 25px;
}

.venue-extras.upselling .extras-modal .extras-page .extra-category > .description {
    padding: 0;
    border: none;
    margin-top: 10px;
    margin-bottom: 15px;
}

.venue-extras.upselling .extras-modal .extras-page .category-items {
    display: grid;
    grid-template-columns: 2fr 2fr;
    gap: 15px;
}

.venue-extras.upselling .extras-modal .extras-page .item {
    height: 300px;
    box-shadow: 0px 0px 8px 8px rgba(27, 28, 36, 0.08);
    display: flex;
    flex-direction: column;
    border-radius: 20px;
}

.venue-extras.upselling .extras-modal .extras-page .item .image {
    border-radius: 20px 20px 0 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border: none;
    height: 160px;
    width: 100%;
}

.venue-extras.upselling .extras-modal .extras-page .item .name {
    color: #333;
    font-size: 15px;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
    padding: 15px 15px 0;
    border-radius: 20px 20px 0 0;
}

.venue-extras.upselling .extras-modal .extras-page .item .description {
    padding: 15px 15px 20px;
    border: none;
    font-size: 13px;
    line-height: 1.2;
    margin-bottom: auto;
}

.venue-extras.upselling .extras-modal .extras-page .item-quantity-selector {
    align-items: center;
    display: flex;
    user-select: none;
    background-color: #fff;
    border-top: 1px solid #ededed;
    color: #292929;
    margin: auto 0 0;
    padding: 5px 15px;
    width: 100%;
    height: 42px;
    border-radius: 0 0 20px 20px;
}

.venue-extras.upselling .extras-modal .extras-page .item-quantity-selector .item-price {
    display: flex;
    margin-right: auto;
}

.venue-extras.upselling .extras-modal .extras-page .item-quantity-selector .icon-svg-container {
    align-items: center;
    border-radius: 15px;
    display: flex;
    height: 30px!important;
    justify-content: center;
    width: 30px!important;
}

.venue-extras.upselling .extras-modal .extras-page .item-quantity-selector .icon-svg-container:hover {
    background: #f6f6f6;
}

.venue-extras.upselling .extras-modal .extras-page .boton-confirmar-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    padding: 25px;
    border-top: 1px solid lightgrey;
}

.venue-extras.upselling .extras-modal .extras-page .boton-confirmar-container > a {
    width: 100%;
}

.venue-extras.upselling .extras-modal .extras-page .boton-confirmar-container > a span {
    margin-left: 1em;
}

.venue-extras.upselling .extras-modal .description-text {
    position: relative;
}

.venue-extras.upselling .extras-modal .read-more-link {
    position: absolute;
    right: 17px;
    top: 16px;
    padding: 6px 15px;
    font-size: 11px;
    border: 1px solid var(--gris-texto);
    text-transform: uppercase;
    color: var(--gris-texto);
    font-weight: 600;
}

@media(max-width: 1150px) {
    .venue-extras .extras .category-extras {
        grid-template-columns: 1fr;
    }
}

@media(max-width: 992px) {
    .venue-extras.upselling .sidebar .inner {
        position: relative;
    }

    .venue-extras .sidebar .boton-confirmar-container {
        position: unset;
    }

    .venue-extras.upselling .extras-modal .calendar-page .date-display {
        display: none;
    }

    .venue-extras.upselling .extras-modal .extras-page .category-items {
        grid-template-columns: 1fr;
    }

    .venue-extras.upselling .extras-modal .main-page .description-text {
        height: 150px;
    }

    .venue-extras.upselling .carousel-mobile .horizontal-scroll-container {
        display: flex;
    }

    body.scrolled .venue-extras.upselling .main > .categorias .inner {
        top: 51px;
        left: 0;
        right: 0;
    }

    .venue-extras.upselling .extras .category-extras .extra {
        padding-right: 0;
    }

    .venue-extras.upselling .extras .category-extras .extra .image {
        top: 0;
        margin-right: -5px;
    }

    .venue-extras.upselling .extras .category-extras .extra .lower-tags {
        flex-direction: column;
    }

    .venue-extras.upselling .extras .category-extras .extra .lower-tags > * {
        margin-right: auto;
        margin-top: 3px;
    }

    .venue-extras.upselling .extras .category-extras .extra .average-rating.mobile-only {
        background: white;
        display: inline-flex !important;
        position: absolute;
        top: 10px;
        right: 10px;
        border: none;
    }

    .venue-extras.upselling .extras-modal .main-page .encuestas-clientes .columna {
        display: flex;
    }

    .venue-extras.upselling .extras-modal .main-page .encuestas-clientes {
        overflow-x: auto;
        scrollbar-width: 0;
    }

    .venue-extras.upselling .extras-modal .main-page .encuestas-clientes::-webkit-scrollbar {
        display: none;
    }

    .venue-extras.upselling .extras-modal .main-page .encuestas-clientes .category {
        width: 63vw;
    }
}