@font-face {
	font-family: 'Material Symbols Rounded';
	font-style: normal;
	font-weight: 100 700;
	src: url(fonts/material-symbols-rounded.woff2) format('woff2');
  }
  
  .material-symbols-rounded {
	font-family: 'Material Symbols Rounded';
	font-weight: normal;
	font-style: normal;
	font-size: 24px;
	line-height: 1;
	letter-spacing: normal;
	text-transform: none;
	display: inline-block;
	white-space: nowrap;
	word-wrap: normal;
	direction: ltr;
	-webkit-font-feature-settings: 'liga';
	-webkit-font-smoothing: antialiased;
  }

.material-symbols-rounded {
	font-family: "Material Symbols Rounded";
	font-weight: normal;
	font-style: normal;
	font-size: 24px;
	line-height: 1;
	letter-spacing: normal;
	text-transform: none;
	display: inline-block;
	white-space: nowrap;
	word-wrap: normal;
	direction: ltr;
	-webkit-font-feature-settings: "liga";
	-webkit-font-smoothing: antialiased;
	font-weight: 300;
}

@font-face {
	font-family: "Circular";
	src: url("./fonts/CircularXXWeb-Book.woff");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "Circular";
	src: url("./fonts/CircularXXWeb-Bold.woff");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "Lyon";
	src: url("./fonts/LyonDisplay-Regular-Web.woff");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "Lyon";
	src: url("./fonts/Lyon-Text-Web-Black-Regular.woff");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: tock-icons;
	font-style: normal;
	font-weight: 400;
	src: url("./fonts/tock-icons.woff2") format("woff2"), url("./fonts/tock-icons.ttf") format("truetype"), url("./fonts/tock-icons.woff") format("woff"), url("./fonts/tock-icons.svg#tock-icons") format("svg");
}

:root {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

* {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-tap-highlight-color: transparent;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	text-size-adjust: 100%;
}

html {
	height: -webkit-fill-available;
}

body {
	min-height: -webkit-fill-available;
}

html,
body,
#resaca {
	height: 100%;
}

body {
	font-family: Circular, sans-serif;
}

*::-webkit-scrollbar {
	width: 10px;
}

*::-webkit-scrollbar-track {
	background: var(--resaltado-claro);
}

*::-webkit-scrollbar-thumb {
	background-color: var(--resaltado);
	border: 2px solid var(--resaltado-claro);
	border-radius: 10px;
}

#resaca {
	display: block;
	/* display: flex;
    flex-direction: column; */
}

#main {
	flex: 1;
}

a {
	cursor: pointer;
}

p:last-child {
	margin-bottom: 0;
}

.fontawesome {
	font-family: fontawesome;
}

.tock-icon {
	font-family: tock-icons !important;
	font-size: 2.4rem;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-style: normal;
	-webkit-font-feature-settings: normal;
	font-feature-settings: normal;
	font-variant: normal;
	font-weight: 400;
	line-height: 1;
	text-transform: none;
}

.icon_plus:before {
	content: "\e91e";
}

.icon_minus:before {
	content: "\e91c";
}

.mr-auto {
	margin-right: auto;
}

h1 {
	font-family: Circular;
	font-weight: bold;
	font-size: 60px;
	line-height: 1.1;
	letter-spacing: 1px;
}

h2,
h3,
h4,
h5,
h6 {
	color: var(--gris-texto);
	font-family: Circular;
	font-size: 20px;
	line-height: 32px;
	letter-spacing: 1px;
	font-weight: bold;
}

body.scrolled {
	padding-top: 52px;
}

body.scrolled-high {
	padding-top: 155px;
}

body.scroll-lock {
	overflow: hidden;
}

.btn.btn-brown,
header .navbar .navbar-nav .nav-link.nav-btn.btn-brown {
	background: var(--gris-texto);
	color: white;
	letter-spacing: 1px;
}

.btn.btn-brown a {
	color: white;
	transition: 1s;
}

.btn.btn-brown:hover a {
	color: var(--gris-texto);
}

.btn:not(.btn-brown):not(.btn-small) {
	border: 1px solid #292929;
}

section:not(.bg-offwhite):not(.bg-offgrey):not(.bg-grey) {
	background: white;
}

.bg-offwhite {
	background: var(--fondo);
}

.bg-offgrey {
	background: var(--fondo);
}

.bg-grey {
	background: #f6f6f6;
}

.pull-right {
	float: right;
}

.pull-left {
	float: left;
}

.btn {
	max-height: 60px;
	border-radius: 0;
	font-family: Circular;
	position: relative;
	box-shadow: 0 0 1px #fbf6f2;
	transition-duration: 0.5s;
	-webkit-transition-duration: 0.5s;
	transition-timing-function: linear;
	-webkit-transition-timing-function: linear;
	box-shadow: 0px 0 0 #fbf6f2 inset;
	line-height: 1;
	text-transform: uppercase;
	z-index: 2;
	display: inline-block;
	color: #212529;
	text-align: center;
	vertical-align: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-color: transparent;
	border: 1px solid transparent;
	transition: color 500ms ease-in-out, background-color 500ms ease-in-out, border-color 500ms ease-in-out, box-shadow 500ms ease-in-out;
	font-weight: 700;
	font-size: 15px;
	padding: 21px;
}

.rounded {
	border-radius: 0.25rem;
}

.btn:hover {
	color: var(--gris-texto);
	box-shadow: -700px 0 0 #fbf6f2 inset;
	-webkit-box-shadow: -700px 0 0 #fbf6f2 inset;
}

.btn.disabled:hover {
	box-shadow: none;
	-webkit-box-shadow: none;
	color: white;
	cursor: not-allowed;
}

.full-width {
	width: 100%;
	max-width: 100%;
	padding-left: 0;
	padding-right: 0;
	margin-left: 0;
	margin-right: 0;
	overflow: hidden;
}

img {
	max-width: 100%;
	height: auto;
}

.fade-in {
	opacity: 0;
	animation-name: fade-in;
	animation-duration: 500ms;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
	animation-delay: 100ms;
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes shine {
	0% {
		background-position-x: 100%;
	}

	100% {
		background-position-x: 0%
	}
}

.section-subtitle {
	font-family: Circular;
	font-weight: 700;
	color: var(--gris-texto);
	text-transform: uppercase;
	font-size: 15px;
	letter-spacing: 2px;
}

h2.section-title {
	color: var(--gris-texto);
	font-family: Circular;
	font-size: 40px;
	font-weight: bold;
	letter-spacing: 1px;
	line-height: 1;
	margin-top: 1rem;
	padding-top: 0.5rem;
	text-transform: uppercase;
}

span.divider {
	display: flex;
	justify-content: center;
	width: 75px;
	height: 2px;
	background: var(--gris-texto);
	margin: 1.5rem auto;
}

a {
	color: #343a40;
	text-decoration: none;
}

a:hover {
	color: #121416;
	text-decoration: none;
}

.right {
	text-align: right;
}

.margin-left {
	margin-left: 25px;
}

.center {
	text-align: center;
}

header.mobile {
	display: none;
}

.flex-col-align-start {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.no-border-bottom {
	border-bottom: 0 !important;
}

.icon-svg {
	display: block;
}

.icon-svg-container {
	display: inline-flex;
	justify-content: center;
	align-items: center;
}

.mt {
	margin-top: 1em;
}

.pull-right {
	float: right;
}

.mobile-only {
	display: none !important;
}

input,
textarea {
	box-shadow: none;
}

.panel {
	margin: 0 -25px 15px -25px;
	padding: 15px 25px 25px;
	border: 1px solid #eee;
	border-radius: 25px;
	/* box-shadow: 0 0 5px rgb(0 0 0 / 6%); */
}

.btn-small {
	margin-top: 25px;
	padding: 10px 15px;
	font-size: 0.8em;
	border: 1px solid var(--gris-texto);
}

.placeholder-bar {
	display: inline-block;
	background-color: #dedede77;
	border-radius: 5px;
	margin-left: 1em;
	height: 1em;
}

.trumbowyg-editor {
	padding: 10px;
}

.columnas {
	display: flex;
	align-items: center;
}

.expand {
	flex: 1;
}

.auto-table {
	opacity: 0;
}

.align-right {
	text-align: right;
}

.boolean-indicator {
	display: inline-block;
	width: 8px;
	height: 8px;
	border-radius: 4px;
	border: 1px solid var(--gris-texto);
}

.boolean-indicator.active {
	background-color: var(--gris-texto);
}

label.disabled {
	opacity: 0.5;
}

.space-between {
	justify-content: space-between !important;
}

@media (min-width: 1200px) {
	.container,
	.container-lg,
	.container-md,
	.container-sm,
	.container-xl {
		max-width: 1280px;
	}

	.evento.container,
	.evento.container-lg,
	.evento.container-md,
	.evento.container-sm,
	.evento.container-xl {
		max-width: 1140px;
	}

	.full-width {
		width: 100%;
		max-width: 100%;
		margin-left: 0;
		margin-right: 0;
		padding-left: 0;
		padding-right: 0;
	}
}

@media (max-width: 991px) {
	html,
	body,
	#resaca {
		height: unset;
	}

	.desktop-only {
		display: none !important;
	}

	.mobile-only {
		display: inherit !important;
	}

	header:not(.mobile) {
		display: none;
	}

	header.mobile {
		display: block;
	}

	body.scrolled {
		padding-top: 48px;
	}

	.panel {
		margin: 0 0 15px;
	}

	.btn:hover {
		color: white !important;
		box-shadow: inherit;
		-webkit-box-shadow: inherit;
	}

	.btn:active {
		color: var(--gris-texto) !important;
		box-shadow: -600px 0 0 #fbf6f2 inset;
		-webkit-box-shadow: -600px 0 0 #fbf6f2 inset;
	}
}

@media (min-width: 769px) {
	.right-md {
		text-align: right;
	}
}

@import "css/admin.css";
@import "css/admin-editar-negocio.css";
@import "css/async-button.css";
@import "css/auth-guard.css";
@import "css/bloque.css";
@import "css/booking-widget.css";
@import "css/calendar.css";
@import "css/cambiar-contrasena.css";
@import "css/carousel-ciudades.css";
@import "css/carousel-eventos.css";
@import "css/carousel.css";
@import "css/categorized-scroller.css";
@import "css/confirmation-dialog.css";
@import "css/dropdown.css";
@import "css/editor-slider.css";
@import "css/ficha-evento.css";
@import "css/field.css";
@import "css/footer.css";
@import "css/form-input.css";
@import "css/form-toggle.css";
@import "css/formulario-contacto.css";
@import "css/formulario-eventos.css";
@import "css/grid-restaurantes.css";
@import "css/home.css";
@import "css/home-subscriptions.css";
@import "css/subscription-types-bar.css";
@import "css/suscribete.css";
@import "css/icon.css";
@import "css/image-upload.css";
@import "css/imagen-paso.css";
@import "css/json-editor.css";
@import "css/loading-indicator.css";
@import "css/login.css";
@import "css/main-menu.css";
@import "css/mi-cuenta.css";
@import "css/mobile-menu.css";
@import "css/modal.css";
@import "css/pago.css";
@import "css/pago-suscripcion.css";
@import "css/para-restaurantes.css";
@import "css/partners.css";
@import "css/planear-evento.css";
@import "css/popup-cookies.css";
@import "css/preguntas-frecuentes.css";
@import "css/preorder.css";
@import "css/extras.css";
@import "css/upselling.css";
@import "css/preorder-ticket.css";
@import "css/promos.css";
@import "css/registro.css";
@import "css/responsive.css";
@import "css/restaurant-search.css";
@import "css/restaurantes.css";
@import "css/slider.css";
@import "css/stripe-checkout-form.css";
@import "css/tab-menu.css";
@import "css/textos-legales.css";
@import "css/welcome-banner.css";
@import "css/icon-selector.css";
@import "css/video-upload.css";
@import "css/admin-faq.css";
@import "css/image-upload-list.css";
@import "css/admin-pagina-estatica.css";
@import "css/admin-negocios.css";
@import "css/admin-bar.css";
@import "css/input-select.css";
@import "css/time-input.css";
@import "css/ui.css";
@import "css/reserva-completada.css";
@import "css/gracias-encuesta.css";
@import "css/accordion-tab.css";
@import "css/material-icon.css";
@import "css/language-selector.css";
@import "css/user-finder.css";
@import "css/admin-usuarios.css";
@import "css/admin-editar-usuario.css";
@import "css/admin-general.css";
@import "css/admin-emails.css";
@import "css/admin-permisos.css";
@import "css/admin-estadisticas.css";
@import "css/admin-idiomas.css";
@import "css/admin-descuentos.css";
@import "css/widget-attachment-limits.css";
@import "css/tooltip.css";
@import "css/admin-ciudades.css";
@import "css/encuesta.css";
@import "css/country-code-selector.css";
@import "css/calendar-filter.css";

@import url(css/admin.responsive.css);
@import url(css/calendar.responsive.css);
@import url(css/admin-estadisticas.responsive.css);
@import url(css/admin-usuarios.responsive.css);
@import url(css/admin-reservas.responsive.css);
@import url(css/admin-disponibilidad.responsive.css);
